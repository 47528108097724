<template>
  <div v-if="loading">{{ $gettext('Caricamento dei dati del bartender...') }}</div>
  <div v-if="!loading" clas="news-wrapper container">
    <h1 class="title" v-html="title"></h1>
    <div class="news">
      <img :src="headerImage" />
      <div id="body-bar" v-html="body"></div>
    </div>
  </div>
  <gallery-card v-if="gallery.length > 0" :items="gallery"></gallery-card>
</template>

<script>
import GalleryCard from '@/components/shared/GalleryCard';

export default {
  components: { GalleryCard },
  data() {
    return {
      loading: false,
      headerImage: this.$imgPlaceHolder,
      title: '',
      body: '',
      gallery: [],
    };
  },

  name: 'bartender-details',

  methods: {
    domDecoder(str) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        '<!doctype html><body>' + str,
        'text/html',
      );
      return dom.body.textContent;
    },
    async getImage(id) {
      await this.axios
        .get(this.api.getMediaUrl + id)
        .then((res) => {
          this.headerImage = res.data.media_details.sizes.full.source_url;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
    getNewsData() {
      this.loading = true;

      this.axios
        .get(this.api.bartenderData + this.id + "?"  + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          this.title = this.domDecoder(res.data.title.rendered);
          //this.body = this.domDecoder(res.data.content.rendered);
          this.body = res.data.content.rendered;
          this.getImage(res.data.featured_media);

          if (
            res.data.acf.gallery_pagine !== null &&
            res.data.acf.gallery_pagine.length > 0
          ) {
            this.showGallery = true;
            this.gallery = res.data.acf.gallery_pagine;
          }

          this.loading = false;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
  },

  created() {
    this.id = this.$route.params.id;
  },

  mounted() {
    this.getNewsData();
  },
};
</script>

<style scoped>
.news {
  /* margin-top: 120px; */
  text-align: left;
}

h1 {
  margin: 0;
  padding: 30px;
  line-height: 110%;
}
.container {
  width: 100%;
}

img {
  width: 100%;
}

.news-wrapper .title {
  padding: 0px 30px;
}

#body-bar {
  padding: 0 30px;
  line-height: 150%;
}

#body-bar h1,
#body-bar h2,
#body-bar h3 {
  line-height: 110%;
}

.img {
  padding: 20px 0;
}
</style>
